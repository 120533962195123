<template>
  <b-modal
    id="modal-create-invoice-account"
    ref="create-invoice-account-modal"
    title="Nouveau compte de facturation"
    ok-only
    ok-title="Valider"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        label="Nom du compte"
        label-for="name-input"
        invalid-feedback="Un nom de compte est obligatoire"
      >
        <b-form-input
          id="name-input"
          v-model="name"
          :state="nameState"
          required
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useInvoiceAccount from "../useInvoiceAccount";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    name: "",
    nameState: null,
  }),
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.createInvoiceAccount(this.customerId, this.name).then(() => {
        this.$emit("update-invoice-account-list");
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs["create-invoice-account-modal"].toggle("#toggle-btn");
        });
      });
    },
  },
  setup() {
    const { createInvoiceAccount } = useInvoiceAccount();

    return {
      createInvoiceAccount,
    };
  },
};
</script>

<style lang="scss"></style>
