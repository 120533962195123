<template>
  <b-card v-if="customerData.name">
    <b-row>
      <!-- Customer Info: Left col -->
      <b-col
        cols="21"
        xl="2"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Customer Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="
              `${publicPath}` + `images/customers/` + `${customerData.logo}`
            "
            :text="avatarText(customerData.name)"
            size="104px"
            rounded
          />
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="14" xl="5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Nom</span>
            </th>
            <td class="pb-50">
              {{ customerData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Code</span>
            </th>
            <td class="pb-50">
              {{ customerData.code }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Description</span>
            </th>
            <td class="pb-50">
              {{ customerData.description }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">URL</span>
            </th>
            <td class="pb-50">
              {{ customerData.URL }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Enregistrement</span>
            </th>
            <td class="pb-50">
              {{ customerData.registration_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Numéro TVA</span>
            </th>
            <td class="pb-50">
              {{ customerData.vat }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Statut</span>
            </th>
            <td class="pb-50">
              {{ customerData.status }}
            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Business Domain</span>
            </th>
            <td>
              {{ customerData.business_domain }}
            </td>
          </tr>
        </table>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="14" xl="5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Contact légal</span>
            </th>
            <td class="pb-50">
              {{ customerData.contact_legal }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Contact email</span>
            </th>
            <td class="pb-50">
              {{ customerData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Adresse 1</span>
            </th>
            <td class="pb-50">
              {{ customerData.address_1 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Adresse 2</span>
            </th>
            <td class="pb-50">
              {{ customerData.address_2 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Code postal</span>
            </th>
            <td class="pb-50">
              {{ customerData.zip_code }}
            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Pays</span>
            </th>
            <td>
              {{ customerData.country }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'customer-edit', params: { id: customerData.ID } }"
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <span>Editer Client</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BRow, BCol, BButton } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    return {
      avatarText,
    };
  },
};
</script>

<style></style>
