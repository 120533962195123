<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="customer === undefined">
      <h4 class="alert-heading">Error fetching customer data</h4>
      <div class="alert-body">
        No customer found with this customer id. Check
        <b-link class="alert-link" :to="{ name: 'customer-list' }">
          Customer List
        </b-link>
        for other customers.
      </div>
    </b-alert>

    <b-tabs class="mt-1">
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Détails</span>
        </template>

        <template v-if="customer">
          <!-- First Row -->
          <customer-info-card :customer-data="customer" />
        </template>
      </b-tab>
    </b-tabs>

    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="AnchorIcon" />
          <span>Projets</span>
        </template>

        <customer-project-cards v-if="customer.ID" :customerID="customer.ID" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Utilisateurs</span>
        </template>

        <customer-user-list :filtered-company-id="customer.ID" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ApertureIcon" />
          <span>Comptes de facturation</span>
        </template>

        <customer-invoice-account
          v-if="customer.ID"
          :customerID="customer.ID"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import store from "@/store";
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { onUnmounted } from "@vue/composition-api";
import { BAlert, BLink, BTabs, BTab } from "bootstrap-vue";

// tabs
import CustomerUserList from "./CustomerUserList.vue";
import CustomerInfoCard from "./CustomerInfoCard.vue";
import CustomerProjectCards from "./CustomerProjectCards.vue";
import CustomerInvoiceAccount from "./CustomerInvoiceAccount.vue";

// helpers
import useCustomers from "./useCustomers";
import customerStoreModule from "../customerStoreModule";

export default {
  components: {
    BAlert,
    BLink,
    BTabs,
    BTab,

    // Local Components
    CustomerInfoCard,
    CustomerInvoiceAccount,
    CustomerProjectCards,
    CustomerUserList,
  },
  mixins: [PageBoxed],
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = "app-customer";

    const {
      customer,

      fetchCustomerByID,
    } = useCustomers();

    // Pull customerID from router
    const { route } = useRouter();
    const customerID = route.value.params.id;

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });

    fetchCustomerByID(customerID);

    return {
      customer,
    };
  },
};
</script>

<style></style>
