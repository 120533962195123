<template>
  <section>
    <b-row class="match-height my-1">
      <b-col cols="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.modal-create-project
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer un Projet</span>
        </b-button>
      </b-col>
    </b-row>

    <b-alert variant="primary" :show="!projects || projects.length === 0">
      <h4 class="alert-heading">Aucun projet enregistré pour ce partenaire.</h4>
    </b-alert>

    <div class="grid-view wishlist-items mt-2">
      <b-card
        v-for="project in projects"
        :key="project.ID"
        bg-variant="primary"
        no-body
      >
        <b-card-header class="py-0">
          <b-card-title class="text-white font-weight-bolder my-1">
            {{ project.name }}
          </b-card-title>
          <feather-icon
            icon="EditIcon"
            size="18"
            class="cursor-pointer text-white"
            v-b-modal.modal-edit-project
            @click="selectProjectID(project.ID)"
          />
        </b-card-header>

        <b-card-body class="pt-0">
          <b-card-text class="text-white font-weight-bolder mb-1">
            {{ project.description }}
          </b-card-text>
          <b-card-text class="mb-0 text-center">
            <b-link class="mb-2 text-white" @click="newTabTo(project.URL)">
              {{ project.URL }}
            </b-link>
          </b-card-text>
          <b-card-text class="text-white text-center mb-1">
            {{ project.version }}
          </b-card-text>

          <b-card no-body>
            <b-card-header>
              <b-card-title>Contacts</b-card-title>
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="cursor-pointer text-primary"
                v-b-modal.modal-create-contact
                @click="selectProjectID(project.ID)"
              />
            </b-card-header>

            <!-- body -->
            <b-card-body class="py-0">
              <b-alert
                variant="primary"
                :show="!project.contacts || project.contacts.length === 0"
              >
                <h4 class="alert-heading">
                  Aucun contact enregistré pour ce projet.
                </h4>
              </b-alert>
              <div
                v-for="contact in project.contacts"
                :key="contact.updated_at"
                class="employee-task d-flex justify-content-between align-items-center my-1"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="32"
                      :src="
                        resolveAvatarLink(contact.first_name, contact.last_name)
                      "
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ contact.first_name }} {{ contact.last_name }}
                    </h6>
                    <small>{{ contact.job }} - {{ contact.email }}</small>
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                    class="cursor-pointer text-primary"
                    v-b-modal.modal-edit-contact
                    @click="selectContactID(contact.ID)"
                  />
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
    </div>

    <modal-create-project
      v-if="customerID"
      :customer-id="customerID"
      @update-project-list="updateProjectList"
    />

    <modal-create-contact
      :params="params"
      @update-project-list="updateProjectList"
    />

    <modal-edit-project
      :params="params"
      @update-project-list="updateProjectList"
    />

    <modal-edit-contact
      :params="params"
      @update-project-list="updateProjectList"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BAlert,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from "bootstrap-vue";
import { resolveAvatarLink } from "@core/utils/utils";
import useProjects from "./useProjects";
import Ripple from "vue-ripple-directive";

// modals
import ModalCreateProject from "./modal-projects/ModalCreateProject.vue";
import ModalCreateContact from "./modal-projects/ModalCreateContact.vue";
import ModalEditProject from "./modal-projects/ModalEditProject.vue";
import ModalEditContact from "./modal-projects/ModalEditContact.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BAlert,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BLink,

    ModalCreateProject,
    ModalCreateContact,
    ModalEditProject,
    ModalEditContact,
  },
  directives: {
    Ripple,
  },
  props: {
    customerID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      projects,
      params,

      findProjectsByCompanyID,
    } = useProjects();

    findProjectsByCompanyID(props.customerID);

    return {
      projects,
      params,

      findProjectsByCompanyID,
    };
  },
  methods: {
    resolveAvatarLink,
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
    selectProjectID(projectID) {
      this.params.selectedProjectID = projectID;
    },
    selectContactID(contactID) {
      this.params.selectedContactID = contactID;
    },
    updateProjectList() {
      this.findProjectsByCompanyID(this.customerID);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>
