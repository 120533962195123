<template>
  <section>
    <b-row class="match-height my-1">
      <b-col cols="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.modal-create-invoice-account
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer un Compte de Facturation</span>
        </b-button>
      </b-col>
    </b-row>

    <b-alert
      variant="primary"
      :show="!invoiceAccounts || invoiceAccounts.length === 0"
    >
      <h4 class="alert-heading">
        Aucun compte de facturation pour ce partenaire.
      </h4>
    </b-alert>

    <div class="grid-view wishlist-items mt-2">
      <b-card
        v-for="invoiceAccount in invoiceAccounts"
        :key="invoiceAccount.ID"
        no-body
      >
        <b-card-header class="py-0">
          <b-card-title class="text-primary font-weight-bolder mt-1">
            {{ invoiceAccount.name }}
          </b-card-title>
        </b-card-header>

        <b-card-body class="pt-50">
          <span
            >Créé le: {{ formatReverseDate(invoiceAccount.created_at) }}</span
          >

          <b-card no-body>
            <b-card-header class="pb-0">
              <b-card-title>Souscriptions</b-card-title>
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="cursor-pointer text-primary"
                v-b-modal.modal-create-subscription
                @click="selectInvoiceAccountID(invoiceAccount.ID)"
              />
            </b-card-header>
            <hr />

            <b-alert
              variant="primary"
              :show="
                !invoiceAccount.subscriptions ||
                invoiceAccount.subscriptions.length === 0
              "
            >
              <h4 class="alert-heading">
                Aucune souscription pour ce compte de facturation.
              </h4>
            </b-alert>

            <b-row>
              <b-col
                md="4"
                v-for="subscription in invoiceAccount.subscriptions"
                :key="subscription.created_at"
                class="text-center"
              >
                <b-card
                  bg-variant="info"
                  no-header
                  class="cursor-pointer mooving-card"
                  @click="
                    $router.push({
                      name: `customer-invoice-account`,
                      query: {
                        subscriptionID: subscription.ID,
                        productCode: findProductFromStore(
                          subscription.product_ID
                        ).code,
                      },
                    })
                  "
                >
                  <span class="text-white font-weight-bold">{{
                    findProductFromStore(
                      subscription.product_ID
                    ).code.toUpperCase()
                  }}</span>
                  <hr class="my-50" />
                  <small class="text-white">{{
                    formatReverseDate(subscription.created_at)
                  }}</small>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-card-body>
      </b-card>
    </div>

    <modal-create-invoice-account
      v-if="customerID"
      :customer-id="customerID"
      @update-invoice-account-list="updateInvoiceAccountList"
    />

    <modal-create-subscription
      :params="params"
      @update-invoice-account-list="updateInvoiceAccountList"
    />
  </section>
</template>

<script>
import store from "@/store";
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardTitle,
  BAlert,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useInvoiceAccount from "./useInvoiceAccount";

// modals
import ModalCreateInvoiceAccount from "./modal-invoice-accounts/ModalCreateInvoiceAccount.vue";
import ModalCreateSubscription from "./modal-invoice-accounts/ModalCreateSubscription.vue";

// Custom formater
import { formatReverseDate } from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BAlert,
    BCardHeader,
    BCardBody,

    ModalCreateInvoiceAccount,
    ModalCreateSubscription,
  },
  directives: {
    Ripple,
  },
  props: {
    customerID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      invoiceAccounts,
      params,

      fetchInvoiceAccounts,
    } = useInvoiceAccount();

    fetchInvoiceAccounts(props.customerID);

    return {
      invoiceAccounts,
      params,

      fetchInvoiceAccounts,
    };
  },
  methods: {
    formatReverseDate,
    findProductFromStore(productID) {
      return store.state.products.products.find(
        (product) => product.ID === productID
      );
    },
    updateInvoiceAccountList() {
      this.fetchInvoiceAccounts(this.customerID);
    },
    selectInvoiceAccountID(invoiceAccountID) {
      this.params.selectedInvoiceAccountID = invoiceAccountID;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>
