<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-create-subscription"
      ref="create-subscription-modal"
      title="Création d'une souscription"
      centered
      ok-title="Valider"
      cancel-title="Retour"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="productState"
          label="Produit"
          label-for="product-select"
          invalid-feedback="Le produit est nécessaire"
        >
          <b-form-radio-group
            id="product-select"
            v-model="params.selectedProductID"
            :options="productOptions"
            :state="productState"
            value-field="ID"
            text-field="code"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import { BFormGroup, BFormRadioGroup, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useInvoiceAccount from "../useInvoiceAccount";

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    "params.selectedInvoiceAccountID"(val, oldVal) {
      if (val !== oldVal) {
        this.invoiceAccountID = val;
      }
    },
  },
  data() {
    return {
      productOptions: store.state.products.products.filter(
        (product) => product.category === "API"
      ),
      productState: null,
      invoiceAccountID: null,
    };
  },
  setup() {
    const { createSubscription } = useInvoiceAccount();

    return {
      createSubscription,
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.productState = valid;
      return valid;
    },
    resetModal() {
      this.productState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.createSubscription(
        this.params.selectedProductID,
        this.invoiceAccountID
      ).then(() => {
        this.$emit("update-invoice-account-list");
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs["create-subscription-modal"].toggle("#toggle-btn");
        });
      });
    },
  },
};
</script>
