import { ref } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInvoiceAccount() {
  // Use toast
  const toast = useToast();

  const invoiceAccounts = ref([]);
  const params = ref({
    selectedInvoiceAccountID: "",
    selectedProductID: "",
  });

  const fetchInvoiceAccounts = (customerID) => {
    store
      .dispatch("invoiceAccounts/findInvoiceAccountsByCompanyID", {
        companyId: customerID,
        perPage: 20,
        page: 1,
      })
      .then((response) => {
        invoiceAccounts.value = response.data.invoice_accounts;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title:
              "Impossible de récupérer la liste des comptes de facturation",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const createInvoiceAccount = (customerID, name) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("invoiceAccounts/createInvoiceAccount", {
          name,
          company_ID: customerID,
        })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Création validée",
              icon: "EditIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la création",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(err);
        });
    });
  };

  const createSubscription = (productID, invoiceAccountID) => {
    return new Promise((resolve, reject) => {
      const newSubscription = {
        product_ID: productID,
        invoice_account_ID: invoiceAccountID,
        start_date: new Date(),
        duration_days: 300,
        type: "DEVELOPMENT",
        status: "ENABLED",
      };
      store
        .dispatch("products/createSubscription", { newSubscription })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Création validée",
              icon: "EditIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la création",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(err);
        });
    });
  };

  return {
    invoiceAccounts,
    params,

    fetchInvoiceAccounts,
    createInvoiceAccount,
    createSubscription,
  };
}
