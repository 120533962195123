<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-create-project"
      ref="create-project-modal"
      title="Création d'un projet"
      centered
      ok-title="Valider"
      cancel-title="Retour"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Nom"
          label-for="name-input"
          invalid-feedback="Le nom est nécessaire"
        >
          <b-form-input
            id="name-input"
            v-model="newProject.name"
            placeholder="Nom du projet"
            :state="nameState"
            required
          />
        </b-form-group>
        <b-form-group
          :state="descriptionState"
          label="Description"
          label-for="description-input"
          invalid-feedback="La description est nécessaire"
        >
          <b-form-textarea
            v-model="newProject.description"
            placeholder="Description du projet"
            rows="3"
            :state="descriptionState && value.length <= maxChar"
            required
            class="char-textarea"
            :class="value.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="value.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ value.length }}</span> / {{ maxChar }}
          </small>
        </b-form-group>
        <b-form-group label="Version" label-for="version-input">
          <b-form-input
            id="version-input"
            v-model="newProject.version"
            placeholder="v1.0.0"
          />
        </b-form-group>
        <b-form-group label="URL" label-for="url-input">
          <b-form-input
            id="url-input"
            v-model="newProject.URL"
            placeholder="https://www.project.com"
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BFormTextarea,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useProjects from "../useProjects";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      value: "",
      maxChar: 1024,
      newProject: {},
      nameState: null,
      descriptionState: null,
    };
  },
  setup() {
    const { createProject } = useProjects();

    return {
      createProject,
    };
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.descriptionState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.descriptionState = null;
      this.newProject = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.newProject.company_ID = this.customerId;

      this.createProject(this.newProject).then(() => {
        this.$emit("update-project-list");
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs["create-project-modal"].toggle("#toggle-btn");
        });
      });
    },
  },
};
</script>
