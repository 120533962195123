<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-create-contact"
      ref="create-contact-modal"
      title="Création d'un contact"
      centered
      ok-title="Valider"
      cancel-title="Retour"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="firstNameState"
          label="Prénom"
          label-for="first-name-input"
          invalid-feedback="Le prénom est nécessaire"
        >
          <b-form-input
            id="first-name-input"
            v-model="newContact.first_name"
            placeholder="Prénom"
            :state="firstNameState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="lastNameState"
          label="Nom"
          label-for="last-name-input"
          invalid-feedback="Le nom est nécessaire"
        >
          <b-form-input
            id="last-name-input"
            v-model="newContact.last_name"
            placeholder="Nom"
            :state="lastNameState"
            required
          />
        </b-form-group>

        <b-form-group label="Poste" label-for="job-input">
          <b-form-input
            id="job-input"
            v-model="newContact.job"
            placeholder="Directeur qualité"
          />
        </b-form-group>

        <b-form-group
          :state="emailState"
          label="Email"
          label-for="email-input"
          invalid-feedback="L'email est nécessaire"
        >
          <b-form-input
            id="email-input"
            v-model="newContact.email"
            type="email"
            placeholder="john.doe@email.com"
            :state="emailState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="genderState"
          label="Genre"
          label-for="gender-input"
          invalid-feedback="Le genre est nécessaire"
        >
          <b-form-radio-group
            id="gender-input"
            v-model="newContact.gender"
            :options="options.gender"
            :state="genderState"
            required
          />
        </b-form-group>

        <b-form-group label="Mobile" label-for="phone-input">
          <b-input-group>
            <b-input-group-prepend is-text> FR (+33) </b-input-group-prepend>
            <cleave
              id="phone-input"
              v-model="newContact.mobile"
              :raw="false"
              class="form-control"
              :options="options.phone"
              placeholder="06 06 06 06 06"
            />
          </b-input-group>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BInputGroupPrepend,
  BInputGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.fr";
import useProjects from "../useProjects";

export default {
  components: {
    BFormGroup,
    BFormInput,
    Cleave,
    BModal,
    BInputGroupPrepend,
    BInputGroup,
    BFormRadioGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      value: "",
      maxChar: 1024,
      newContact: {},
      firstNameState: null,
      lastNameState: null,
      genderState: null,
      emailState: null,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "FR",
        },
        gender: [
          { value: "MALE", text: "Homme" },
          { value: "FEMALE", text: "Femme" },
          { value: "OTHER", text: "Autre" },
        ],
      },
    };
  },
  setup() {
    const { createContact } = useProjects();

    return {
      createContact,
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.firstNameState = valid;
      this.lastNameState = valid;
      this.genderState = valid;
      this.emailState = valid;
      return valid;
    },
    resetModal() {
      this.firstNameState = null;
      this.lastNameState = null;
      this.genderState = null;
      this.emailState = null;
      this.newContact = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.newContact.project_ID = this.params.selectedProjectID;

      this.createContact(this.newContact).then(() => {
        this.$emit("update-project-list");
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs["create-contact-modal"].toggle("#toggle-btn");
        });
      });
    },
  },
};
</script>
