import { ref } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProjects() {
  // Use toast
  const toast = useToast();

  const projects = ref([]);
  const params = ref({
    selectedProjectID: "",
    selectedContactID: "",
  });

  const findProjectsByCompanyID = (companyID) => {
    store
      .dispatch("app-customer/findProjectsByCompanyID", { companyID })
      .then((response) => {
        projects.value = response.data.projects;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching projects list: ",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const createProject = (newProject) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/createProject", { newProject })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Création validée",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la création",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const createContact = (newContact) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/createContact", { newContact })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Création validée",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la création",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const editProject = (updateProject) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/editProject", { updateProject })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Projet modifié",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la modification",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const editContact = (updateContact) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/editContact", { updateContact })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Contact modifié",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la modification",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const findProjectByID = (projectID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/findProjectByID", { projectID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur projet introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const findContactByID = (contactID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/findContactByID", { contactID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur contact introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const deleteProjectByID = (projectID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/deleteProjectByID", { projectID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur projet introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const deleteContactByID = (contactID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-customer/deleteContactByID", { contactID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur contact introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  return {
    projects,
    params,

    findProjectsByCompanyID,
    createProject,
    createContact,
    editProject,
    editContact,
    findProjectByID,
    findContactByID,
    deleteProjectByID,
    deleteContactByID,
  };
}
