<template>
  <div>
    <b-row class="match-height my-1">
      <b-col cols="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'user-create' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer Utilisateur</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card no-body class="mx-5 mt-2">
      <b-table
        ref="refUserListTable"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Client -->
        <template #cell(utilisateur)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  resolveAvatarLink(data.item.first_name, data.item.last_name)
                "
                :text="avatarText(data.item.first_name)"
                :to="{ name: 'user-detail', params: { id: data.item.ID } }"
              />
            </template>
            <b-link
              :to="{ name: 'user-detail', params: { id: data.item.ID } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.first_name }} {{ data.item.last_name }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Emploi -->
        <template #cell(emploi)="data">
          <span class="text-nowrap">
            {{ data.item.job_role }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(statut)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.from }} à {{ dataMeta.to }} pour
              {{ dataMeta.of }} utilisateurs</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";
import { useRouter } from "@core/utils/utils";
import useCustomers from "./useCustomers";

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      fetchUsers,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      resolveStatusVariant,
    } = useCustomers();

    // Pull customerID from router
    const { route } = useRouter();

    filterCompanyId.value = route.value.params.id;

    return {
      fetchUsers,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      avatarText,
      resolveStatusVariant,
      resolveAvatarLink,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
